<template>
  <div></div>
</template>

<script>
export default {
  name: 'PCIFail',
  mounted () {
    window.parent.failCallBack(this.$route.query)
  }
}
</script>

<style scoped>

</style>
